import React from "react";
import Rating from "./Rating";
import RatingsWrapper, { CardContainer } from "./ratings.style";

import ph from "common/assets/image/Customers/ph.webp";
import tr from "common/assets/image/Customers/tr.webp";
import cap from "common/assets/image/Customers/cap.webp";
import g2 from "common/assets/image/Customers/g2.svg";

const Ratings = () => {
  return (
    <>
      <h2 style={{ textAlign: "center", fontSize: "32px" }}>
        Reviews and Ratings on
      </h2>
      <RatingsWrapper>
        <CardContainer>
          <a href="https://www.g2.com/products/clodura-ai/reviews#reviews">
            <img
              src={
                "https://www.g2.com/shared-assets/product-badges/users-love-us.svg"
              }
              alt="G2 Logo"
            />
          </a>
          <Rating rating={4.5} />
        </CardContainer>
        <CardContainer>
          <a href="https://www.capterra.com/p/165908/Clodura/">
            <img src={cap} alt="CapTerra Logo" />
          </a>
          <Rating rating={4.4} />
        </CardContainer>
        <CardContainer>
          <a href="https://www.producthunt.com/products/clodura-ai">
            <img src={ph} alt="ProductHunt Logo" />
          </a>
          <Rating rating={5} />
        </CardContainer>
        <CardContainer>
          <a href="https://www.trustradius.com/products/clodura/reviews">
            <img src={tr} alt="TrustRadius Logo" />
          </a>
          <Rating rating={4.5} />
        </CardContainer>
      </RatingsWrapper>
    </>
  );
};

export default Ratings;
