import React, { Fragment } from "react";
import Heading from "common/components/Heading";
import Box from "common/components/Box";

import VideosContainer from "./videos.style";
import PropTypes from "prop-types";

const Videos = ({ title }) => {
  return (
    <Fragment>
      <Heading {...title} content="Watch Video Reviews by Users" />
      <VideosContainer>
        <Box>
          <iframe
            width="350"
            height="275"
            src="https://www.youtube.com/embed/pTIfDA1s8Xk"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </Box>
        <Box>
          <iframe
            width="350"
            height="275"
            src="https://www.youtube.com/embed/em88l-QiXXY"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </Box>
        <Box>
          <iframe
            width="350"
            height="275"
            src="https://www.youtube.com/embed/XlaFfJAyXOI"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </Box>
        <Box>
          <iframe
            width="350"
            height="275"
            src="https://www.youtube.com/embed/5XXZBffnRrY"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </Box>
        <Box>
          <iframe
            width="350"
            height="275"
            src="https://www.youtube.com/embed/9nq7AhOQByo"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </Box>
        <Box>
          <iframe
            width="350"
            height="275"
            src="https://www.youtube.com/embed/OsVKywCRWmE"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </Box>
        <Box>
          <iframe
            width="350"
            height="275"
            src="https://www.youtube.com/embed/UktZ_ARkerI"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </Box>
        <Box>
          <iframe
            width="350"
            height="275"
            src="https://www.youtube.com/embed/T9XtysjbdsU"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </Box>
        <Box>
          <iframe
            width="350"
            height="275"
            src="https://www.youtube.com/embed/qiT1j9wNhpk"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </Box>
      </VideosContainer>
    </Fragment>
  );
};

Videos.propTypes = {
  title: PropTypes.object,
};

Videos.defaultProps = {
  title: {
    fontSize: ["24px", "30px", "30px", "40px", "40px"],
    fontWeight: "600",
    textAlign: "center",
  },
};

export default Videos;
