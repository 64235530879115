import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import ZoomInfoHeaderWrapper from "./zoominfoHeader.style";
import { Link } from "gatsby";
const ZoomInfoHeader = () => {
  const ZoominfoPageData = useStaticQuery(graphql`
    {
      allStrapiReviewPageHeaderImage {
        nodes {
          img {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  `);

  const img = ZoominfoPageData.allStrapiReviewPageHeaderImage.nodes[0];

  return (
    <ZoomInfoHeaderWrapper>
      <div className="text">
        <h1>
          Customer Reviews:
          <br /> See What People Are Saying
        </h1>
        <p>
          Don't just take our word for it, though - hear from our satisfied
          customers themselves! Check out the reviews and testimonials below to
          see what people are saying about their experiences with us.
        </p>
        <Link href="/app/#/auth/register">
          <button>Free Sign Up</button>
        </Link>
      </div>

      <img className="right-img" src={`${img.img.localFile.publicURL}`} />
    </ZoomInfoHeaderWrapper>
  );
};

export default ZoomInfoHeader;
