import styled from "styled-components";

const ReviewsWrapper = styled.section`
  display: flex;
  justify-content: space-evenly;
  background-color: whitesmoke;

  button {
    color: white;
    background-color: #03baed;
    border: none;
    font-weight: 500;
    font-size: 16px;
    font-family: "Poppins";
    border-radius: 5px;
    width: 250px;
    height: 50px;
    margin-bottom: 10px;
    cursor: pointer;
    :hover {
      background-color: #edcd37;
      color: black;
      transition: all 0.3s ease 0s;
    }
    @media screen and (max-width: 420px) {
      font-size: 12px;
      width: 100%;
      height: 40px;
    }
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const ReviewsBox = styled.div`
  margin-top: 20px;
  width: 29%;
  padding: 20px 20px 0px 20px;
  text-align: center;
  border: 1px solid #03baed;
  border-radius: 5%;
  margin-bottom: 20px;
  background-color: white;
  /* margin: auto; */

  .star {
    color: #03baed;
    margin-bottom: 3px;
  }

  .feature-size {
    text-align: center;
    font-size: 30px;
    background-color: whitesmoke;
  }

  @media screen and (max-width: 600px) {
    width: 90%;
  }

  > .reviews-image {
    margin: 0 auto;
    height: 100px;
    width: 100px;
    border-radius: 50% 50%;
    background: url(${(props) => (props.avatar ? props.avatar : null)});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
  }

  > h4 {
    min-height: 15px;
    @media screen and (max-width: 1024px) {
      min-height: 80px;
    }
    @media screen and (max-width: 768px) {
      min-height: 100px;
    }
    @media screen and (max-width: 600px) {
      min-height: unset;
    }
  }

  > p {
    color: #a3a3a3;
    font-size: 14px;

    min-height: 200px;

    @media screen and (max-width: 1024px) {
      min-height: 220px;
    }
    @media screen and (max-width: 768px) {
      min-height: 300px;
    }
    @media screen and (max-width: 600px) {
      min-height: unset;
    }
  }

  > svg {
    color: #03baed;
    font-size: 40px;
  }
`;
export const ReviewsBoxFace = styled.div`
  margin-top: 20px;
  width: 29%;
  padding: 20px 20px 0px 20px;
  text-align: center;
  border: 1px solid #03baed;
  border-radius: 5%;
  margin-bottom: 20px;
  background-color: white;
  /* margin: auto; */

  .star {
    color: #03baed;
    margin-bottom: 3px;
  }

  .feature-size {
    text-align: center;
    font-size: 30px;
    background-color: whitesmoke;
  }

  @media screen and (max-width: 600px) {
    width: 90%;
  }

  > .reviews-image {
    margin: 0 auto;
    height: 80px;
    width: 80px;
    border-radius: 50% 50%;
    background: url(${(props) => (props.avatar ? props.avatar : null)});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
  }

  > h4 {
    min-height: 15px;
    @media screen and (max-width: 1024px) {
      min-height: 80px;
    }
    @media screen and (max-width: 768px) {
      min-height: 100px;
    }
    @media screen and (max-width: 600px) {
      min-height: unset;
    }
  }

  > p {
    color: #a3a3a3;
    font-size: 14px;

    min-height: 50px;

    @media screen and (max-width: 1024px) {
      min-height: 220px;
    }
    @media screen and (max-width: 768px) {
      min-height: 300px;
    }
    @media screen and (max-width: 600px) {
      min-height: unset;
    }
  }

  > svg {
    color: #1877f2;
    font-size: 60px;
  }
`;

export default ReviewsWrapper;
