import React from "react";
import Samuel from "../../../../../common/assets/image/saasModern/Samuel.jpg";
import Blank from "../../../../../common/assets/image/saasModern/Blank.jpg";
import Pierrer from "../../../../../common/assets/image/saasModern/Pierre-Axel.jpg";
import RobertS from "../../../../../common/assets/image/saasModern/Robert S.webp";
import Steve from "../../../../../common/assets/image/saasModern/Steve.jpg";
import Proma from "../../../../../common/assets/image/saasModern/Proma N.jpeg";
import Dann from "../../../../../common/assets/image/saasModern/Dann.jpg";

// import Radu from "../../../../../common/assets/image/saasModern/radu.jpg";
// import James from "../../../../../common/assets/image/saasModern/james.jpg";
// import Ahat from "../../../../../common/assets/image/saasModern/Ahat.jpg";
// import SK from "../../../../../common/assets/image/saasModern/sk.jpg";
// import Megan from "../../../../../common/assets/image/saasModern/Megan.jpg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteRight } from "@fortawesome/free-solid-svg-icons";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faStarHalf } from "@fortawesome/free-solid-svg-icons";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";

import { Link } from "gatsby";

import ReviewsWrapper, { ReviewsBox, ReviewsBoxFace } from "./reviews.style";

const Reviews = () => {
  return (
    <>
      <h2
        style={{ textAlign: "center", fontSize: "36px", padding: "0px 10px" }}
      >
        What our customers say
      </h2>
      <h3
        style={{
          textAlign: "center",
          fontSize: "30px",
          padding: "0px 10px",
        }}
      >
        Reviews on G2
      </h3>
      <ReviewsWrapper id="g2">
        <ReviewsBox avatar={RobertS}>
          <FontAwesomeIcon icon={faQuoteRight} />
          <p>
            The possibility to find all the businesses and people that work for
            those businesses with their contact informatio. Not to mention the
            chrome extension that comes with the app.
            <br />
            Finding potential leads and their contact so I can email them and
            try to sale my web design and development services to their
            business.
          </p>
          <div className="star">
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
          </div>
          <div className="reviews-image"></div>
          <h3>Robert S.</h3>
          <h4>Sviluppatore front-end</h4>
        </ReviewsBox>
        <ReviewsBox avatar={Steve}>
          <FontAwesomeIcon icon={faQuoteRight} />
          <p>
            Excellent good looking product that has all the normal leadgen
            attibutes, but also some very powerful AI Account Mapping
            capabilites and an inbuilt emailing system that can run on
            autopiolot. The GUI is also very intutitive and easy to use. I
            beleive a data enrichment tool is planned which would make this a go
            to leadgen platform
          </p>
          <div className="star">
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStarHalf} />
          </div>
          <div className="reviews-image"></div>
          <h3>Steve W.</h3>
          {/* <h4>Chief Burnout Officer</h4> */}
        </ReviewsBox>
        <ReviewsBox avatar={Blank}>
          <FontAwesomeIcon icon={faQuoteRight} />
          <p>
            There is a lot of functionality - I'm still learning everything it
            can do. I like that you can find emails and then put those prospects
            into an email cadence. I like that you are not charged a credit if
            they can't find a valid email after it's unlocked. I also like that
            it won't add duplicates to a list if you try to add one. Overall,
            I'm very pleased with this platform!
          </p>
          <div className="star">
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
          </div>
          <div className="reviews-image"></div>
          <h3>Claudia L.</h3>
          {/* <h4>General Manager</h4> */}
        </ReviewsBox>
      </ReviewsWrapper>
      <ReviewsWrapper>
        <ReviewsBox avatar={Proma}>
          <FontAwesomeIcon icon={faQuoteRight} />
          <p>
            I have only just begun to use Clodura. What I observed was creating
            a persona was a matter of a few clicks and you instantly get a good
            list.
            <br />I would say not having to pay for the hefty Sales Nav fee on
            LinkedIn. Clodura provides great deals of customization including
            hiring areas, website keywords and funding and it is specific enough
            to return targeted results.
          </p>
          <div className="star">
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            {/* <FontAwesomeIcon icon={faStar} /> */}
          </div>
          <div className="reviews-image"></div>
          <h3>Proma N.</h3>
          <h4>Founder and Marketing Specialist</h4>
        </ReviewsBox>
        <ReviewsBox avatar={Blank}>
          <FontAwesomeIcon icon={faQuoteRight} />
          <p>
            I've recently had the pleasure of using Clodura.AI to generate
            targeted leads for my business, and I'm incredibly impressed with
            the results. Their platform offers an incredibly accurate
            technographic data set, allowing us to generate a highly targeted
            list of potential buyers with ease. Their AI-recommended list of
            potential buyers also enabled us to expand our sales outreach with
            confidence.
          </p>
          <div className="star">
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
          </div>
          <div className="reviews-image"></div>
          <h3>Amine B.</h3>
          <h4>General Manager</h4>
        </ReviewsBox>
        <ReviewsBox avatar={Dann}>
          <FontAwesomeIcon icon={faQuoteRight} />
          <p>
            I like the intuitive nature of Coldura, the AI programming allows
            for automated lead generation, it also is seamless in experience and
            its startup for a brand like mine. The business problems that
            Coldura helps to solve are lead attraction, generation and
            automating the experience. With a special focus on customizing the
            experience for any brand that you are tapping into.
          </p>
          <div className="star">
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            {/* <FontAwesomeIcon icon={faStar} /> */}
          </div>
          <div className="reviews-image"></div>
          <h3>Danielle S.</h3>
          <h4>Senior Digital Account Manager & Strategist</h4>
        </ReviewsBox>
      </ReviewsWrapper>
      <ReviewsWrapper>
        <ReviewsBox avatar={Blank}>
          <FontAwesomeIcon icon={faQuoteRight} />
          <p>
            Clodura filtered my sales lead upon entry with a new employer With
            Clodura I was up to speed with my task a business development
            associate as against competition. Competition miss out or a sales
            lead details such as email and phone number which are a very
            critical component for cold calls or emails as the case may be.
            Clodura fills that gap for me.
          </p>
          <div className="star">
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
          </div>
          <div className="reviews-image"></div>
          <h3>Brian E.</h3>
          {/* <h4>Advisor</h4> */}
        </ReviewsBox>
        <ReviewsBox avatar={Blank}>
          <FontAwesomeIcon icon={faQuoteRight} />
          <p>
            One of the standout features of Clodura.AI is its intuitive user
            interface. The platform's design is sleek, user-friendly, and highly
            customizable, making it easy for sales professionals to navigate and
            personalize their experience. The comprehensive dashboards present
            data visually appealingly, enabling users to grasp complex
            information at a glance.
          </p>
          <div className="star">
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
          </div>
          <div className="reviews-image"></div>
          <h3>Anupam K.</h3>
          {/* <h4>Digitalizing Agent</h4> */}
        </ReviewsBox>
        <ReviewsBox avatar={Blank}>
          <FontAwesomeIcon icon={faQuoteRight} />
          <p>
            I found it pretty straightforward to use and the small issue I did
            have was resolved very quickly by a real human on the other end of
            the chat! Very fast and responsive customer service who were able to
            resolve my issue within minutes by looking remotely at my account
            then sending me a screenshot and highlighting the area I needed to
            address - brilliant.
          </p>
          <div className="star">
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
          </div>
          <div className="reviews-image"></div>
          <h3>Simone B.</h3>
          {/* <h4></h4> */}
        </ReviewsBox>
      </ReviewsWrapper>
      <ReviewsWrapper>
        <Link
          href="https://www.g2.com/products/clodura-ai/reviews#reviews"
          target={"_blank"}
          style={{ textAlign: "center" }}
        >
          <button>Read More Reviews on G2</button>
        </Link>
      </ReviewsWrapper>
      {/* other component */}
      <h3 style={{ textAlign: "center", fontSize: "30px" }}>
        Read the Shout out on Social Media
      </h3>
      {/* <ReviewsWrapper>
        <ReviewsBoxFace avatar={Radu}>
          <FontAwesomeIcon icon={faFacebook} />
          <p>
            Sending the first emails today using the app, looks promising! Can't
            wait to see the results. Also I fomo-ed in with 3 codes, might stack
            up on more.
          </p>
          <div className="reviews-image"></div>

          <h3>Radu Balas</h3>
        </ReviewsBoxFace>
        <ReviewsBoxFace avatar={James}>
          <FontAwesomeIcon icon={faFacebook} />
          <p>
            It's gonna be best Alternative to ZoomInfo. Any public roadmap?
            <br />
            <br />
          </p>
          <div className="reviews-image"></div>

          <h3>James Vince</h3>
        </ReviewsBoxFace>
        <ReviewsBoxFace avatar={Ahat}>
          <FontAwesomeIcon icon={faFacebook} />
          <p>
            Best sales intelligence platform ever
            <br />
            <br />
            <br />
          </p>
          <div className="reviews-image"></div>

          <h3>Ahatasham K.</h3>
        </ReviewsBoxFace>
      </ReviewsWrapper>

      <ReviewsWrapper>
        <ReviewsBoxFace avatar={SK}>
          <FontAwesomeIcon icon={faFacebook} />
          <p>Superb Advanced Search filters!</p>
          <div className="reviews-image"></div>

          <h3>Sk Nayon Ali</h3>
        </ReviewsBoxFace>

        <ReviewsBoxFace avatar={Megan}>
          <FontAwesomeIcon icon={faFacebook} />
          <p>This is something I need</p>
          <div className="reviews-image"></div>

          <h3>Megan Brain</h3>
        </ReviewsBoxFace>
        <ReviewsBoxFace avatar={Blank}>
          <FontAwesomeIcon icon={faFacebook} />
          <p>
            Auto Email verification of database is the best feature. DO you have
            a plan for Linkedln outrech.
          </p>
          <div className="reviews-image"></div>

          <h3>Farrukh Khurshed</h3>
        </ReviewsBoxFace>
      </ReviewsWrapper> */}

      <ReviewsWrapper>
        <ReviewsBox avatar={Samuel}>
          <FontAwesomeIcon icon={faQuoteRight} />
          <p>
            It has a user-friendly interface. The ability to search for specific
            companies using various filters such as revenue, industry, company
            size, buyer intent, and more provides unmatched flexibility. Whether
            I need to target companies in a particular sector or identify
            potential buyers with specific characteristics, its filters make it
            a breeze.
          </p>
          <h4>"Unleashing Sales Potential with Detail Lead Data"</h4>

          <div className="star">
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
          </div>
          <h3>danikeliat</h3>
        </ReviewsBox>
        <ReviewsBox avatar={Samuel}>
          <FontAwesomeIcon icon={faQuoteRight} />
          <p>
            The best lead generation solution tested this year, very
            user-friendly and good UX. Searches are fast, as are contact
            selections. The links to Linkedin are very practical, and I'm
            thinking of taking the plan above. A very pleasant surprise, which
            I'd recommend to anyone wishing to develop their business without
            getting in over their heads (but the solution doesn't call contacts
            for you!).
          </p>
          <h4>"So far so good. I went from 1 code to 5 codes."</h4>

          <div className="star">
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
          </div>
          <h3>lee447</h3>
        </ReviewsBox>
        <ReviewsBox avatar={Samuel}>
          <FontAwesomeIcon icon={faQuoteRight} />
          <p>
            Clodura is exactly what we needed, as a small business we don't have
            hours to spend on our outreach, so we took a look at clodura and
            it's serving us well so far. it's quick and easy to get started
            with. UI is nice, I like the addition of being able to use dark
            mode.
          </p>
          <h4>"More Valid data than most"</h4>

          <div className="star">
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
          </div>
          <h3>SW-Designs</h3>
        </ReviewsBox>
      </ReviewsWrapper>
      <ReviewsWrapper>
        <ReviewsBox avatar={Samuel}>
          <FontAwesomeIcon icon={faQuoteRight} />
          <p>
            A good database of leads that is further enhanced with a useful
            organizational chart.
            <br />
            The team at Clodura.ai have addressed my questions in a timely
            manner and I would rate them as excellent overall. Excellent
            overall!
          </p>
          <h4>"Excellent"</h4>

          <div className="star">
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
          </div>
          <h3>john575</h3>
        </ReviewsBox>
        <ReviewsBox avatar={Samuel}>
          <FontAwesomeIcon icon={faQuoteRight} />
          <p>
            I already purchased 5 codes. the database is huge and accurate. I
            use it everyday.
            <br /> Would be great to see advanced automations sequences, warmup
            option, and a unibox. In general, I highly recommend this tool
          </p>
          <h4>"A game changer"</h4>

          <div className="star">
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
          </div>
          <h3>Mohamed</h3>
        </ReviewsBox>
        <ReviewsBox avatar={Samuel}>
          <FontAwesomeIcon icon={faQuoteRight} />
          <p>
            On Appsumo, only Clodura offers hiring intent. It is useful for
            agencies because it tells you who is hiring right now and what they
            look for. For example, if you sell web design services, it tells who
            and where has the intent to buy such services.
          </p>
          <h4>"Hiring Intent is deal maker "</h4>

          <div className="star">
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
          </div>
          <h3>teycir</h3>
        </ReviewsBox>
      </ReviewsWrapper>
      <ReviewsWrapper>
        <ReviewsBox avatar={Samuel}>
          <FontAwesomeIcon icon={faQuoteRight} />
          <p>
            This is my first time actually using any kind of cold email
            marketing software and I am really impressed with Clodura. You
            definitely should watch through all the tutorials to understand the
            workflow. But, once you do, it's pretty smooth sailing!
          </p>
          <h4>"Really Impressed!"</h4>

          <div className="star">
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
          </div>
          <h3>mikev</h3>
        </ReviewsBox>
        <ReviewsBox avatar={Samuel}>
          <FontAwesomeIcon icon={faQuoteRight} />
          <p>
            Although I haven't had the opportunity to fully explore its
            functionality, I am already blown away by the level of satisfaction
            it gave me, especially with their outstanding customer service.
            During the cadence setup, I encountered some challenges and reached
            out to their support through the chat feature.
          </p>
          <h4>"Clodura exceeded my expectations"</h4>

          <div className="star">
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
          </div>
          <h3>jewelry_business_growth</h3>
        </ReviewsBox>
        <ReviewsBox avatar={Samuel}>
          <FontAwesomeIcon icon={faQuoteRight} />
          <p>
            WOW! This is way more features than I expected. I am excited to
            start prospecting again with this program. It provides ALL the
            details I need for an effective cold outreach campaign.
          </p>
          <h4>"Outside Sales "</h4>

          <div className="star">
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
          </div>
          <h3>mbustamonte</h3>
        </ReviewsBox>
      </ReviewsWrapper>

      {/* end of the section */}
      <h3 style={{ textAlign: "center", fontSize: "30px" }}>
        How Clodura.AI is better than ZoomInfo
      </h3>
      <ReviewsWrapper style={{ marginBottom: "20px" }}>
        <ReviewsBox avatar={Pierrer}>
          <FontAwesomeIcon icon={faQuoteRight} />
          <p>
            It has the very potential to become one of the great one of the
            market Data management is almost perfect and very simular to
            Zoominfo Buying intent is interesting , cross finger to see if get
            some leads from it data for france is good so far .
          </p>
          <div className="star">
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStarHalf} />
          </div>
          <div className="reviews-image"></div>
          <h3>Pierre-Axel B.</h3>
          <h4>Inside Sales Executive</h4>
        </ReviewsBox>
        <ReviewsBox avatar={Blank}>
          <FontAwesomeIcon icon={faQuoteRight} />
          <p>
            I've been in lead generation for 5+ years and this tool has a great
            potential to compete with big players like Zoominfo and Apollo. I am
            looking forward to buy more codes. However, beware the database is
            not 100% correct (which is also the case with a premium tool like
            Zoominfo) I am looking forward to see this tool grow and not stop
            here.
          </p>
          <div className="star">
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
          </div>
          <div className="reviews-image"></div>
          <h3>akshay.kini7</h3>
          {/* <h4>Founder</h4> */}
        </ReviewsBox>
        <ReviewsBox avatar={Blank}>
          <FontAwesomeIcon icon={faQuoteRight} />
          <p>
            This is a good database that I now use in addition to ZoomInfo and
            LinkedIn Sales Navigator since it provides additional companies
            within my sales territory I can pursue. It also provides ISV
            information. Thus, I highly recommend!
          </p>
          <div className="star">
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
          </div>
          <div className="reviews-image"></div>
          <h3>kenrickc77</h3>
          {/* <h4>Founder</h4> */}
        </ReviewsBox>
      </ReviewsWrapper>

      {/* ################# video ##################### */}
    </>
  );
};

export default Reviews;
