import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/contexts/DrawerContext';
import { saasModernTheme } from 'common/theme/saasModern';
import { ResetCSS } from 'common/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
} from 'containers/SaasModern/sassModern.style';
import Navbar from 'containers/SaasModern/Navbar';
import ZoomInfoHeader from '../containers/Custom/Landing/Zoominfo/ReviewPageHeader';
import Reviews from '../containers/Custom/Landing/Zoominfo/ReviewsPage';
import Footer from 'containers/AgencyModern/Footer';
import Ratings from 'containers/Custom/Zoominfo/Ratings/index2.js';
import Seo from 'components/seo';
import Videos from '../containers/Custom/Customers/ReviewVideo';
import Directory from '../containers/Directory';
import Thanksgiving from '../containers/SaasModern/Thanksgiving';
import G2Slider from '../containers/SaasModern/G2Badges';
import BookDemoButton from '../containers/SaasModern/ScheduleAdemo/ButtonDemo';

const ReviewPage = () => {
  const ZoominfoPageData = useStaticQuery(graphql`
    {
      allStrapiComparePageClientLogo {
        nodes {
          title
          logos {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  `);

  const clientLogosData =
    ZoominfoPageData.allStrapiComparePageClientLogo.nodes[0];

  return (
    <ThemeProvider theme={saasModernTheme}>
      <Fragment>
        <Seo
          title=" Customer Reviews: See What People Are Saying | Clodura.AI"
          description="Discover what our customers are saying about their experiences with us. Read our reviews to see why people choose to do business with Clodura.AI."
          robots="index, follow"
          canonical="https://www.clodura.ai/reviews/"
        />
        <ResetCSS />

        <GlobalStyle />
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
              {/* <Thanksgiving /> */}
            </DrawerProvider>
          </Sticky>
          <ZoomInfoHeader />
          <G2Slider />
          <Ratings />
          <Reviews />
          <Videos />
          {/* <Directory /> */}
          <Footer />
          <BookDemoButton />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
export default ReviewPage;
